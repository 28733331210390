import React from "react";
import PropTypes from "prop-types";
import {GatsbyImage as Img} from "gatsby-plugin-image";
import classNames from "classnames";

import Card from "../common/Card";
import ButtonLink from "../common/ButtonLink";

import * as classes from "./ProductLink.module.scss";

export default function ProductLink({className, imageClassName, title, description, keyPoints, slug, image}) {
    return (
        <Card className={classNames(classes.container, className)}>
            <div className={classes.content}>
                <h2>{title}</h2>
                <p>{description}</p>
                <ul>
                    {keyPoints.map((value, i) =>
                        <li key={i}>{value}</li>)}
                </ul>
                <ButtonLink to={`/products/${slug}`}>Подробнее</ButtonLink>
            </div>
            <Img
                className={classNames(classes.image, imageClassName)}
                image={image}
                alt={title} />
        </Card>
    );
}

ProductLink.propTypes = {
    className: PropTypes.string,
    imageClassName: PropTypes.string,
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    keyPoints: PropTypes.arrayOf(PropTypes.string).isRequired,
    slug: PropTypes.string.isRequired,
    image: PropTypes.object.isRequired
};
