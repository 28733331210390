import React from "react";
import {Helmet} from "react-helmet";
import {graphql} from "gatsby";
import {getImage} from "gatsby-plugin-image";
import classNames from "classnames";

import {useIsMobile} from "../../util/responsiveness";
import ProductLink from "../../components/products/ProductLink";
import SeeAlso from "../../components/common/SeeAlso";

import * as classes from "./index.module.scss";

export default function ProductsPage({data}) {

    const isMobile = useIsMobile();

    return (
        <>
            <Helmet>
                <title>Наши продукты</title>
                <meta name="description" content="Продукция компании «РУССЭЛЛ»: биочипы для онкодиагностики, транспортные среды для цитологии, целевые транспортные среды для COVID-19 и другое." />
            </Helmet>
            <section>
                <h1>Наши продукты</h1>
                <ProductLink
                    className={classNames(classes.link, classes.linkBiochip)}
                    imageClassName={classes.linkBiochipImage}
                    title="Тест-системы «Биочип»"
                    description="Принципиально новый технологический уровень экспресс-диагностики ЗНО."
                    keyPoints={[
                        "Стандартизованный тест под одного пациента;",
                        "Точность не менее 93%;",
                        "До 3-х одномоментно определяемых маркеров."
                    ]}
                    slug="biochip"
                    image={getImage(data["linkBiochipImageFile"])} />
                <ProductLink
                    className={classNames(classes.link, classes.linkTps1)}
                    imageClassName={classes.linkTps1Image}
                    title="Транспортно-питательная среда «ТПС-1»"
                    description="Высший стандарт хранения и транспортировки биоматериала."
                    keyPoints={[
                        "Сохраняет морфологию клеток до 7 дней при комнатной температуре;",
                        "Инновационный состав;",
                        "Разнообразие фасовок."
                    ]}
                    slug="tps-1"
                    image={getImage(data["linkTps1ImageFile"])} />
                <ProductLink
                    className={classNames(classes.link, classes.linkTpsCovid19)}
                    imageClassName={classes.linkTpsCovid19Image}
                    title="Транспортная среда «ТПС - COVID-19»"
                    description="Лучшее решение для длительной транспортировки мазков."
                    keyPoints={[
                        "Длительные транспортировка и хранение;",
                        "Две разных фасовки;",
                        "Наличие на складе."
                    ]}
                    slug="tps-covid-19"
                    image={getImage(data["linkTpsCovid19ImageFile"])} />
            </section>
            <SeeAlso
                contentClassName={classes.seeAlsoContent}
                linkText={<>Перейти к пайплайну {!isMobile && <br />}разработок</>}
                linkTo="/research-and-development">
                Мы не только уверенно смотрим в будущее, но и создаем его.
                Оцените наши новые проекты, которые мы активно разрабатываем уже сейчас.
            </SeeAlso>
        </>
    );

}

export const pageQuery = graphql`
    query {
        linkBiochipImageFile: file(relativePath: {eq: "products/biochip.png"}) {
            childImageSharp {
                gatsbyImageData(layout: CONSTRAINED, width: 721, quality: 95, placeholder: NONE)
            }
        }
        linkTps1ImageFile: file(relativePath: {eq: "products/tps-1.png"}) {
            childImageSharp {
                gatsbyImageData(layout: CONSTRAINED, width: 550, quality: 95, placeholder: NONE)
            }
        }
        linkTpsCovid19ImageFile: file(relativePath: {eq: "products/tps-covid-19.png"}) {
            childImageSharp {
                gatsbyImageData(layout: CONSTRAINED , width: 550, quality: 95, placeholder: NONE)
            }
        }
    }
`;
